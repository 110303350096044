import React from 'react';
import { Twitter, Book, FileText, BookOpen, Mail } from 'lucide-react';

const ResearchWebsite = () => {
  // Research projects data
  const projects = [
    {
      title: "We Need A Science of Flourishing Built on Metamodernism",
      type: "Essay",
      description: "We need a field that pursues what matters (flourishing) rather than what is only instrumental (progress); and that pursuit needs to be rooted in a philosophy that meets the complexity of the modern world (metamodernism) — rather than a naive (modern) or cynical (post-modern) philosophy.",
      link: "https://www.super-humanist.org/we-need-a-science-of-flourishing-built-on-metamodernism-2/",
      icon: <BookOpen className="h-6 w-6" />
    },
    {
      title: "Evolution and The Knightian Blindspot of Machine Learning",
      type: "Academic Paper",
      description: "Machine learning may have a blindspot for unknown unknowns (Knightian uncertainty); biological evolution thrives on Knightian uncertainty, and perhaps our algorithms can as well.",
      link: "https://arxiv.org/abs/2501.13075",
      icon: <Book className="h-6 w-6" />
    },
    {
        title: "Interactive Poetry Breeding through Base Model LLMs",
        type: "Blog Post",
        description: "Base model LLMs are powerful pattern-completion engines. With minimal prompting they can interactively breed text (like poetry, or quotes, or Python programs) to complement human creativity.",
        link: "https://blog.joellehman.com/interactive-poetry-breeding-through-mixtral-base-model-llms.html",
        icon: <FileText className="h-6 w-6" />
    },
    {
        title: "Identifying Life-Changing Books with LLMs",
        type: "Blog Post",
        description: "Language models can analyze millions of book reviews, to identify books most likely for readers to say it 'changed their life.'",
        link: "https://blog.joellehman.com/identifying-life-changing-books-with-llms.html",
        icon: <FileText className='h-6 w-6' />
    },
    {
      title: "We Need Positive Visions for AI Grounded in Wellbeing",
      type: "Essay",
      description: "How do we ensure AI benefits humanity?",
      link: "https://thegradient.pub/we-need-positive-visions-for-ai-grounded-in-wellbeing/",
      icon: <BookOpen className="h-6 w-6" />
    },
    {
      title: "Machine Love",
      type: "Academic Paper",
      description: "While there is no consensus on defining human flourishing, from diverse perspectives across psychology, philosophy, and spiritual traditions, love is understood to be one of its primary catalysts. Motivated by this view, this paper explores whether there is a useful conception of love fitting for machines to embody.",
      link: "https://arxiv.org/abs/2302.09248",
      icon: <BookOpen className="h-6 w-6" />
    },
    {
      title: "Evolution Through Large Models",
      type: "Academic Paper",
      description: "LLMs can enable new forms of evolutionary algorithms, and can help LLMs become more open-endedly creative.",
      link: "https://arxiv.org/abs/2206.08896",
      icon: <Book className="h-6 w-6" />
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Header */}
      <header className="py-16 border-b border-gray-800">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center md:items-start mb-8">
            <div className="md:mr-8 mb-6 md:mb-0">
              <img 
                src="/headshot.jpg"
                alt="Joel Lehman" 
                className="w-48 h-48 rounded-full object-cover border-2 border-gray-700"
              />
            </div>
            <div>
              <h1 className="text-4xl font-bold mb-4">Joel Lehman</h1>
              <p className="text-xl max-w-3xl mb-6 text-gray-300">
                I'm a machine learning researcher. The unifying theme behind my research is <a href="https://www.oreilly.com/radar/open-endedness-the-last-grand-challenge-youve-never-heard-of/" target="_blank" rel="noopener noreferrer">open-endedness</a> --
                the study of processes of creative divergence, like biological evolution, science, art, and markets.
                Recently I'm interested in the tension between open-endedness and safety -- both in the context of AI
                and in human society. My main technical expertise is in evolutionary computation, reinforcement learning,
                LLMs, and artificial life.
              </p>
            </div>
          </div>
          
          <div className="flex space-x-4">
            <a 
              href="https://scholar.google.com/citations?hl=en&user=GcvxHWQAAAAJ&view_op=list_works&sortby=pubdate" 
              target="_blank" 
              rel="noopener noreferrer"
              className="flex items-center text-white border border-gray-700 px-4 py-2 hover:bg-gray-900 transition-colors"
            >
              <Book className="mr-2 h-5 w-5" />
              Google Scholar
            </a>
            <a 
              href="https://twitter.com/joelbot3000" 
              target="_blank" 
              rel="noopener noreferrer"
              className="flex items-center text-white border border-gray-700 px-4 py-2 hover:bg-gray-900 transition-colors"
            >
              <Twitter className="mr-2 h-5 w-5" />
              @joelbot3000
            </a>
            <a 
              href="mailto:lehman.154@gmail.com"
              className="flex items-center text-white border border-gray-700 px-4 py-2 hover:bg-gray-900 transition-colors"
            >
              <Mail className="mr-2 h-5 w-5" />
              Email
            </a>
          </div>
        </div>
      </header>

      {/* Featured Book */}
      <section className="py-8 bg-gray-900 border-b border-gray-800">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/4 mb-4 md:mb-0 flex justify-center">
              <a 
                href="https://www.amazon.com/Why-Greatness-Cannot-Planned-Objective/dp/3319155237" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img 
                  src="/cover_hires_50pcnt.png" 
                  alt="Why Greatness Cannot Be Planned Book Cover" 
                  className="w-32 h-auto border border-gray-700 hover:opacity-90 transition-opacity"
                />
              </a>
            </div>
            <div className="md:w-3/4 md:pl-6">
              <a 
                href="https://www.amazon.com/Why-Greatness-Cannot-Planned-Objective/dp/3319155237" 
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-gray-300 transition-colors"
              >
                <h2 className="text-2xl font-bold mb-1">Why Greatness Cannot Be Planned</h2>
              </a>
              <p className="text-md text-gray-300 mb-2">
                The Myth of the Objective
              </p>
              <p className="text-sm text-gray-400 mb-3">
                This book challenges conventional thinking about achievement, arguing that innovative discoveries 
                often come from serendipity rather than direct objective-based approaches.
              </p>
              <div className="flex space-x-3">
                <a 
                  href="https://www.amazon.com/Why-Greatness-Cannot-Planned-Objective/dp/3319155237" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-sm flex items-center text-white border border-gray-700 px-3 py-1 hover:bg-gray-800 transition-colors"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Recent Research */}
      <main className="container mx-auto px-4 py-12">
        <h2 className="text-2xl font-bold mb-8">Recent Work</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {projects.map((project, index) => (
            <a 
              key={index}
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block border border-gray-800 p-6 hover:bg-gray-900 transition-colors no-underline"
            >
              <div>
                <div className="flex items-center mb-4">
                  {project.icon}
                  <span className="ml-2 text-sm font-medium text-gray-400">{project.type}</span>
                </div>
                <h3 className="text-xl font-bold mb-2 text-white">{project.title}</h3>
                <p className="text-gray-400 mb-4">{project.description}</p>
                <span className="text-gray-300 hover:text-white font-medium">
                  Read More →
                </span>
              </div>
            </a>
          ))}
        </div>
      </main>

      {/* Footer */}
      <footer className="border-t border-gray-800 py-8">
        <div className="container mx-auto px-4 text-center text-gray-500">
          <p>© {new Date().getFullYear()} Joel Lehman</p>
        </div>
      </footer>
    </div>
  );
};

export default ResearchWebsite;